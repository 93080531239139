<template>
  <div>
    <FwbP size="lg">
      <I18nT keypath="challenge_create.invitation_users_for_amount">
        <template #challenge_users>
          <span class="font-medium">
            <span v-if="challengedUsers.length > 0">
              {{ challengedUsers[0] }}
              <span v-if="challengedUsers.length > 1">
                +{{ challengedUsers.length - 1 }}
              </span>
            </span>
            <span v-else>
              {{ $t("you") }}
            </span>
          </span>
        </template>
        <template #challenge_amount>
          {{ formatPrice(amount) }}
        </template>
      </I18nT>
    </FwbP>
    <FwbP size="2xl">{{ name }}</FwbP>
  </div>
  <div class="border-b" />
  <FwbTextarea
    v-model="v.description.$model"
    class="mt-2"
    :rows="4"
    :placeholder="$t('challenge_create.place_a_challenge_description')"
    :class="{
      '!text-red-500': v.description.$error && v.description.$dirty,
    }"
  />
  <div class="flex items-center flex-wrap gap-2 w-full">
    <FwbP class="mr-2">
      {{ $t("tags") }}
    </FwbP>
    <HashTag
      v-for="(hashTag, i) in hashTags"
      :key="hashTag"
      ref="hashTagElements"
      :text="hashTag"
      editable
      icon="/assets/images/hashtag.svg"
      color="lightGrey"
      @update:text="(text: string) => updateHashTag(i, text)"
      @remove="removeTag(i)"
    />
    <HashTagButton
      v-if="hashTags && hashTags.length < 15"
      @click="() => addTag('')"
    />
  </div>
  <div class="flex flex-col gap-2">
    <FwbP>{{ $t("expiration_date") }}</FwbP>
    <FwbInput
      v-model="v.expirationDate.$model"
      class="mb-2 bg-transparent"
      type="date"
      :min="today"
    />
  </div>
  <div class="flex flex-col gap-2">
    <FwbP>{{ $t("challenge_create.challenge_visibility") }}</FwbP>
    <div class="flex gap-2 items-center">
      <ChallengeVisibilityDropdown v-model="visibility" />
    </div>
  </div>

  <FwbCheckbox
    v-model="v.acceptCredit.$model"
    :label="$t('challenge_create.credit_confirm')"
  />

  <ul v-if="v.$errors.length" class="text-red-400 w-full list-disc mt-4">
    <li v-for="error in v.$errors" :key="error.$uid">
      {{ error.$message }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useCreateChallengeStore } from "~/stores/createChallenge";

import FwbP from "~/components/Flowbite/Typography/FwbP.vue";
import FwbInput from "~/components/Flowbite/FwbInput/FwbInput.vue";
import FwbCheckbox from "~/components/Flowbite/FwbCheckbox/FwbCheckbox.vue";
import FwbTextarea from "~/components/Flowbite/FwbTextarea/FwbTextarea.vue";

import ChallengeVisibilityDropdown from "~/components/challenge/ChallengeVisibilityDropdown.vue";
import HashTagButton from "~/components/partials/HashTagButton.vue";
import HashTag from "~/components/partials/HashTag.vue";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  minLength,
  required,
  sameAs,
} from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";

const acceptCredit = ref(false);
const hashTagElements = ref([]);
const { t } = useI18n();

const newChallengeStore = useCreateChallengeStore();
const {
  name,
  description,
  challengedUserEmailsString,
  hashTags,
  amount,
  expirationDate,
  visibility,
} = toRefs(newChallengeStore);

const challengedUsers = computed(() =>
  getEmailsFromText(challengedUserEmailsString.value ?? "")
);

const today = format(new Date(), "yyyy-MM-dd");

const v = useVuelidate(
  {
    description: {
      required: helpers.withMessage(
        t("create_challenge_page.validate_description"),
        required
      ),
      maxLength: helpers.withMessage(
        t("form.errors.exceed_character_limit", { limit: 300 }),
        maxLength(300)
      ),
    },
    hashTags: {
      required: helpers.withMessage(
        t("create_challenge_page.validate_tag"),
        minLength(1)
      ),
    },
    expirationDate: {
      required,
      minValue: helpers.withMessage(
        t("create_challenge_page.validate_date"),
        (value: string) => new Date(value) > new Date()
      ),
    },
    acceptCredit: {
      sameAs: helpers.withMessage(
        t("create_challenge_page.validate_credit"),
        sameAs(true)
      ),
    },
  },
  {
    description,
    hashTags,
    expirationDate,
    acceptCredit,
  }
);

const removeTag = (index: number) => {
  hashTags.value.splice(index, 1);
  v.value.hashTags.$touch();
};

const addTag = async (text: string) => {
  hashTags.value = [...hashTags.value, text];
  v.value.hashTags.$touch();

  await nextTick();

  const newHashTagElement: typeof HashTag =
    hashTagElements.value[hashTags.value.length - 1];

  newHashTagElement.focus();
};

const updateHashTag = (index: number, text: string) => {
  hashTags.value.splice(index, 1, text);
};

onMounted(() => {
  if (hashTags.value.length) v.value.hashTags.$touch();
});
</script>
