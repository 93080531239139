<template>
  <div class="text-2xl">
    <div class="flex flex-row items-center gap-2 flex-wrap font-send-default">
      <I18nT keypath="challenge_create.invitation">
        <template #challenge_users>
          <FwbP
            v-if="!challengedUserEmails?.length && challengedUsername"
            class="text-send-purple-1 bg-transparent text-bold"
            @click="emit('challenge-friend-button-click')"
          >
            {{ challengedUsername }}
          </FwbP>
          <FwbP
            v-else-if="challengedUsername && challengedUserEmails?.length"
            class="text-send-purple-1 bg-transparent text-bold"
            @click="emit('challenge-friend-button-click')"
          >
            {{ challengedUsername }},
            {{ challengedUserEmails?.[0] }}
            <span v-if="challengedUserEmails.length > 1">
              +{{ challengedUserEmails.length - 1 }}
            </span>
          </FwbP>
          <FwbP
            v-else-if="challengedUserEmails?.length"
            class="text-send-purple-1 bg-transparent text-bold"
            @click="emit('challenge-friend-button-click')"
          >
            {{ challengedUserEmails?.[0] }}
            <span v-if="challengedUserEmails.length > 1">
              +{{ challengedUserEmails.length - 1 }}
            </span>
          </FwbP>
          <FwbP v-else>
            {{ $t("you") }}
          </FwbP>
        </template>
        <template #challenge_name>
          <div class="w-full">
            <FwbInput
              v-model="v.name.$model"
              size="xl"
              :placeholder="$t('challenge_create.challenge_name_placeholder')"
              class="text-send-purple-1 bg-transparent sc-header-h1 w-full"
              :class="[
                v.name.$errors.length && v.name.$dirty
                  ? '!text-red-500 !border-2 !border-red-500'
                  : 'border-none',
              ]"
            />
            <ul
              v-if="v.name.$errors.length && v.name.$dirty"
              class="text-red-500 text-sm"
            >
              <li v-for="error in v.name.$errors" :key="error.$uid">
                {{
                  error.$validator === "maxLength"
                    ? $t("form.errors.exceed_character_limit", { limit: 100 })
                    : $t(`form.errors.${error.$validator}`)
                }}
              </li>
            </ul>
          </div>
        </template>
        <template #challenge_amount>
          <FwbInput
            v-model="v.amount.$model"
            type="number"
            min="0"
            class="text-send-purple-1 bg-transparent border-none text-xl w-32"
            :placeholder="5"
          />
        </template>
      </I18nT>
    </div>
  </div>

  <div v-if="!challengedUsername" class="flex items-center">
    <FwbButton
      type="button"
      color="transparent-border"
      @click="emit('challenge-friend-button-click')"
    >
      <div class="flex items-center">
        {{ $t("challenge_friends") }}
        <img class="ml-2" src="/assets/images/plus_sm.svg" alt="plus icon" />
      </div>
    </FwbButton>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { maxLength, minValue, numeric, required } from "@vuelidate/validators";
import { useCreateChallengeStore } from "~/stores/createChallenge";

import FwbP from "~/components/Flowbite/Typography/FwbP.vue";
import FwbInput from "~/components/Flowbite/FwbInput/FwbInput.vue";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";

const emit = defineEmits(["challenge-friend-button-click"]);

const newChallengeStore = useCreateChallengeStore();
const { name, amount, challengedUserEmailsString, challengedUsername } =
  toRefs(newChallengeStore);

const challengedUserEmails = computed(() =>
  getEmailsFromText(challengedUserEmailsString.value ?? "")
);

const v = useVuelidate(
  {
    name: {
      required,
      maxLength: maxLength(100),
    },
    amount: {
      required,
      numeric,
      greaterThanZero: minValue(0.1),
    },
    challengedUserEmails: {
      requiredIf: () =>
        challengedUserEmails.value.length || challengedUsername.value,
    },
    challengedUsername: {
      requiredIf: () =>
        challengedUserEmails.value.length || challengedUsername.value,
    },
  },
  {
    name,
    amount,
    challengedUserEmails,
    challengedUsername,
  }
);
</script>
