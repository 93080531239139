<template>
  <FwbModal v-if="showModal" @close="onClose">
    <template #header>
      <FwbHeading tag="h5" class="text-center">
        {{ $t("log_in") }}
      </FwbHeading>
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <LoginAccountForm @login="onLogin" />
      </div>
    </template>
  </FwbModal>
</template>

<script lang="ts" setup>
import FwbModal from "~/components/Flowbite/FwbModal/FwbModal.vue";
import LoginAccountForm from "~/components/auth/LoginAccountForm.vue";
import FwbHeading from "../Flowbite/Typography/FwbHeading.vue";

defineProps<{
  showModal: boolean;
}>();

const emit = defineEmits(["update:showModal", "login-success"]);

const onClose = () => emit("update:showModal", false);

const onLogin = () => {
  emit("update:showModal", false);
  emit("login-success");
};
</script>
