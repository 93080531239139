<template>
  <div ref="wrapper" class="flex items-center">
    <span
      :class="[tagClass, customTextSize]"
      class="flex items-center gap-2"
      @click="focus"
    >
      <img
        v-if="icon"
        class="max-h-full max-w-full"
        :src="icon"
        alt="hashtag icon"
      />
      <FwbInput
        v-if="editable && isInFocus"
        v-model="text"
        type="text"
        class="ml-2 w-full"
        :class="{
          '!text-red-500': text.length > 30,
        }"
        @blur="blur"
        @keyup.enter.stop="blur"
      />
      <span v-else class="pl-1 whitespace-nowrap">
        {{ text }}
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { onClickOutside } from "@vueuse/core";
import FwbInput from "~/components/Flowbite/FwbInput/FwbInput.vue";
import { debounce } from "throttle-debounce";

const props = withDefaults(
  defineProps<{
    text: string;
    color: "darkGrey" | "mediumGrey" | "lightGrey";
    icon?: string;
    customTextSize?: string;
    editable?: boolean;
  }>(),
  {
    color: "darkGrey",
    editable: false,
    customTextSize: "text-sm",
    icon: undefined,
  }
);

const wrapper = ref<HTMLDivElement>();
const isInFocus = ref(false);
const text = toRef(props.text);

watch(
  text,
  debounce(300, () => {
    if (text.value.length > 30) {
      text.value = text.value.slice(0, 30);
    }
  })
);

const emit = defineEmits(["remove", "update:text"]);

const focus = async () => {
  isInFocus.value = true;
  await nextTick();
  wrapper.value?.querySelector("input")?.focus();
};

const blur = () => {
  isInFocus.value = false;
  if (!text.value) {
    emit("remove");
  } else {
    emit("update:text", text.value.slice(0, 30));
  }
};

onClickOutside(wrapper, blur);

const tagClass = computed(() => {
  const colorClasses = {
    darkGrey: "bg-send-grey-40",
    mediumGrey: "bg-send-grey-20",
    lightGrey: "bg-send-grey-10",
  };

  const selectedColorClass = colorClasses[props.color] ?? "bg-send-grey-80";
  const roundedClass =
    props.color === "darkGrey" || props.color === "mediumGrey"
      ? "rounded-full"
      : "rounded-md";

  return `${selectedColorClass} px-2 py-1 ${roundedClass} ${props.customTextSize}`;
});

defineExpose({ focus, blur });
</script>
