<template>
  <FwbModal v-if="showModal" @close="() => emit('update:show-modal', false)">
    <template #header>
      <FwbHeading tag="h5" class="text-center">
        {{ $t("top_up_balance.top_up_balance") }}
      </FwbHeading>
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <div class="sc-text-body">
          {{ $t("top_up_balance.balance_too_low") }}.
        </div>

        <div>
          <div class="text-color-gradient font-bold sc-text-body-xl">
            {{
              $t("top_up_balance.extra_credits_needed", {
                credits: amount,
              })
            }}
          </div>
        </div>
        <div class="flex items-center justify-between font-bold">
          <span>
            {{ $t("top_up_balance.total_amount") }}
          </span>
          <span>
            {{ formatPrice(amount) }}
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center">
        <FwbButton
          color="dark"
          :loading="mutation.loading.value"
          :disabled="mutation.loading.value"
          @click="topUpBalance"
        >
          {{ $t("top_up_balance.top_up_balance") }}
        </FwbButton>
      </div>
    </template>
  </FwbModal>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import FwbHeading from "~/components/Flowbite/Typography/FwbHeading.vue";
import FwbModal from "~/components/Flowbite/FwbModal/FwbModal.vue";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import { useMutation } from "@vue/apollo-composable";
import { graphql } from "~/gql";

const toast = useToast();
const i18n = useI18n();

const props = defineProps<{
  showModal: boolean;
  creditsNeeded: number;
}>();

const emit = defineEmits({
  "update:show-modal": (value: boolean) => value,
});

// TODO: add conversion rate if other valuates are used (base valuta is EURO)
const amount = computed(() => props.creditsNeeded);

const mutation = useMutation(
  graphql(`
    mutation initializePayment($amount: Int!) {
      InitializePayment(input: { amount: $amount }) {
        payment_url
        status {
          success
          message
          identifier
        }
      }
    }
  `)
);

const topUpBalance = () => {
  // Mollie uses cents as currency, so we multiply the amount by 100
  mutation.mutate({ amount: amount.value * 100 });
};

mutation.onDone(async (result) => {
  if (!result.data?.InitializePayment.status?.success)
    return toast.error(i18n.t("error.payment_link_generation_failed"));

  await navigateTo(result.data?.InitializePayment.payment_url, {
    external: true,
  });
});
</script>
