<template>
  <div
    class="group transition duration-300 ease-in-out transform text-send-grey-100 hover:text-black"
    :class="{
      'opacity-50 cursor-not-allowed': disabled,
      'cursor-pointer': !disabled,
      'rotate-180': direction === 'left',
    }"
    @click="onClick"
  >
    <svg
      width="60"
      height="62"
      viewBox="0 0 80 82"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="79"
        height="80.2613"
        rx="39.5"
        class="fill-current"
      />
      <rect
        x="0.5"
        y="0.5"
        width="79"
        height="80.2613"
        rx="39.5"
        class="stroke-current fill-current"
      />
      <path
        d="M36.2145 26.4862L37.9487 24.8255C38.683 24.1224 39.8704 24.1224 40.5968 24.8255L55.7827 39.3598C56.5169 40.063 56.5169 41.2 55.7827 41.8957L40.5968 56.4375C39.8626 57.1406 38.6752 57.1406 37.9487 56.4375L36.2145 54.7768C35.4724 54.0662 35.488 52.9068 36.2458 52.2111L45.6588 43.6236H23.2082C22.1692 43.6236 21.3334 42.8232 21.3334 41.8284V39.4347C21.3334 38.4398 22.1692 37.6394 23.2082 37.6394H45.6588L36.2458 29.0519C35.4802 28.3563 35.4646 27.1968 36.2145 26.4862Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    direction?: "right" | "left";
  }>(),
  {
    disabled: false,
    direction: "right",
  }
);

const emit = defineEmits(["click"]);

const onClick = () => {
  if (props.disabled) return;
  emit("click");
};
</script>
