import { useCreateChallengeStore } from "@/stores/createChallenge";
import { createChallengeMutation } from "@/graphql/challenge";
import { useMutation } from "@vue/apollo-composable";
import type { ChallengeInput } from "~/gql/graphql";

export default () => {
  const store = useCreateChallengeStore();

  const mutation = useMutation(createChallengeMutation);

  const createChallenge = (input: ChallengeInput) =>
    mutation.mutate({
      input,
    });

  return {
    store,
    mutation,
    createChallenge,
  };
};
