<template>
  <section class="h-screen bg-send-grey-bg">
    <div class="flex items-center justify-center">
      <div class="container px-4 mt-16 max-w-2xl flex flex-col gap-6">
        <div>
          <FwbHeading tag="h3" class="py-4 border-b border-send-grey-100">
            {{ $t("start_new_challenge") }}
          </FwbHeading>
        </div>

        <template v-if="state.step === 'create'">
          <ChallengeCreateStep
            @challenge-friend-button-click="
              () => (state.showChallengeEmailModal = true)
            "
          />

          <div class="border-t border-grey" />

          <div class="flex justify-end">
            <ButtonArrow
              :disabled="v.$invalid"
              @click="state.step = 'details'"
            />
          </div>
        </template>

        <template v-if="state.step === 'details'">
          <ChallengeDetailsStep />

          <div class="border-t border-grey" />

          <div class="flex justify-between">
            <FwbButton @click="state.step = 'create'">
              {{ $t("back") }}
            </FwbButton>

            <FwbButton
              color="dark"
              :loading="mutation.loading.value"
              :disabled="v.$invalid"
              @click.prevent="onCreateChallenge"
            >
              {{
                mutation.loading.value
                  ? `${$t("challenge_create.creating_challenge")}...`
                  : $t("create_challenge")
              }}
            </FwbButton>
          </div>
        </template>
      </div>
    </div>
    <ChallengeEmailModal v-model:showModal="state.showChallengeEmailModal" />
    <LoginModal
      v-model:show-modal="state.showLoginModal"
      @login-success="onCreateChallenge"
    />
    <TopUpBalanceModal
      v-model:show-modal="state.showTopUpBalanceModal"
      :credits-needed="creditsNeeded"
    />
  </section>
</template>

<script setup lang="ts">
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { useAuthStore } from "@/stores/auth";
import useUser from "@/composables/useUser";

import FwbHeading from "~/components/Flowbite/Typography/FwbHeading.vue";
import ChallengeCreateStep from "~/components/challenge/create/ChallengeCreateStep.vue";
import ChallengeDetailsStep from "~/components/challenge/create/ChallengeDetailsStep.vue";
import TopUpBalanceModal from "~/components/modals/TopUpBalanceModal.vue";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import ButtonArrow from "~/components/partials/ButtonArrow.vue";
import ChallengeEmailModal from "~/components/challenge/create/ChallengeEmailModal.vue";
import LoginModal from "~/components/auth/LoginModal.vue";
import { useI18n } from "vue-i18n";

const toast = useToast();
const { userStore } = useUser();
const { getUserBalanceQuery } = useWallet();
const { username } = toRefs(userStore);
const { loggedIn } = toRefs(useAuthStore());

const { store, mutation, createChallenge } = useCreateChallenge();
const {
  name,
  description,
  amount,
  hashTags,
  expirationDate,
  visibility,
  challengedUsername,
  challengedUserEmailsString,
  timestamp,
} = toRefs(store);

const state = reactive<{
  step: "create" | "details";
  showChallengeEmailModal: boolean;
  showLoginModal: boolean;
  balance: number;
  showTopUpBalanceModal: boolean;
}>({
  step: "create",
  showChallengeEmailModal: false,
  showLoginModal: false,
  showTopUpBalanceModal: false,
  balance: 0,
});

const route = useRoute();
const router = useRouter();
const v = useVuelidate();
const { t } = useI18n();

const hasInsufficientFunds = computed(() => state.balance / 100 < amount.value);

const onCreateChallenge = async () => {
  // User should be logged in
  if (!loggedIn.value) return (state.showLoginModal = true);

  // Do not let the user create a challenge if they have insufficient funds
  if (hasInsufficientFunds.value) return (state.showTopUpBalanceModal = true);

  createChallenge({
    challengeDescription: description.value!,
    challengeName: name.value!,
    challengeReward: amount.value,
    challengeVisibility: visibility.value,
    expirationDate: expirationDate.value,
    tags: hashTags.value.map((string: string) => ({ name: string })),
    userEmailsToChallenge: challengedUserEmailsString.value
      ? getEmailsFromText(challengedUserEmailsString.value!).join(", ")
      : undefined,
    usernameToChallenge:
      challengedUsername.value && !challengedUserEmailsString.value
        ? challengedUsername.value
        : undefined,
  });
};

const amountOfUsersChallenged = computed(() =>
  challengedUsername.value
    ? 1
    : getEmailsFromText(challengedUserEmailsString.value ?? "").length
);

const creditsNeeded = computed(
  () => amount.value * amountOfUsersChallenged.value - state.balance / 100
);

// Check if a username is directly challenged (directed from a profile page)
challengedUsername.value =
  (Array.isArray(route.query.username)
    ? route.query.username[0]
    : route.query.username) ?? undefined;

onBeforeMount(() => {
  const difference = Math.abs((Date.now() - timestamp.value) / 60000);
  if (difference > 15) {
    store.flush(); // Flush store when timestamp is older than 15 minutes
  }
});

onMounted(async () => {
  const { load, refetch } = getUserBalanceQuery;
  load() || refetch();

  // If a user is directly challenged, we need to clear the email field (data persistently stored in the store)
  if (challengedUsername.value) challengedUserEmailsString.value = "";

  let initialLoad = true;
  watch(store, () => {
    if (initialLoad) {
      initialLoad = false;
    } else {
      timestamp.value = Date.now();
    }
  });
});

getUserBalanceQuery.onResult((result) => {
  if (!result || result.errors) return;
  state.balance = result.data?.getWallet?.wallet?.availableAmount ?? 0;
});

mutation.onDone((result) => {
  if (!result || result.errors) return;

  const { success, message, identifier } = result.data?.createChallenge
    .status || {
    success: false,
    message: "unexpected error",
  };

  const possibleIdentifiers = [
    "challenge_reward_too_low",
    "insufficient_funds",
    "invalid_expiration_date",
    "owner_equals_challenged_user",
    "user_not_challengeable",
    "minimal_challenge_value_invalid",
    "user_not_found",
  ];

  if (!success && identifier && possibleIdentifiers.includes(identifier)) {
    return toast.error(t(`create_challenge_page.identifier.${identifier}`));
  }

  if (!success) {
    console.error(message);
    return toast.error(t("create_challenge_page.create_error"));
  }

  if (success) {
    getUserBalanceQuery.refetch();
    toast.success(t("create_challenge_page.create_success"));
    toast.success(t("create_challenge_page.email_send"), {
      timeout: 10000,
    });
  }

  store.flush();

  const [firstChallenge] = result.data?.createChallenge?.challenges ?? [null];

  if (firstChallenge) return router.push(`/challenge/${firstChallenge.slug}`);

  if (username?.value)
    return router.push(`/profile/${username.value}/active-challenges`);
});

mutation.onError((error) => {
  console.error(error);
  toast.error(t("create_challenge_page.mutation_error"));
});
</script>
